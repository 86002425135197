.slider{
    width: 100%;
    height:fit-content;
    
}

.ht{
    height:601px !important;
}

.services{
    width: 100%;
    height: fit-content;
    background-color: black;
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: silver!important;
    
   
}

.videoNow{
    position: relative;
}

.services2{
    width: 25%;
    height: 350px;
    background-color:black;
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: silver!important;
    position: absolute;
    top:198px !important;
    left: 50px !important;;
    z-index: 9;
    opacity: 0.7;
    
    padding:15px;
    
}

.alignBook{
    margin-top: 10px;
}

.settingsborder{
    margin-top: 25px;
}

.formsize{
    width: 100% !important;;
    background-color: black;
    color:silver;
    text-align: center!important;

}

.formsize2{
    width: 100% !important;;
}

.facolor{
    color: silver;
}
.margin{
    margin-left: 15px;
}

@media only screen and (min-width: 768px){
    .col-12{
        width: 33.3%;
    }
    
}

@media only screen and (max-width: 768px){
    .paragr{
        display: none;
    }
    .services2{
        display: none;
    }
}

.fix{
    display: inline-block!important;
}

.size{
    width: 200px;
    height: 200px;

}


.card{
    float: left!important;
    box-shadow: 10px 15px 20px rgb(45, 49, 51)!important;
    height: 400px !important;
    width: 500px !important;
    margin-top: 2%!important;
    margin-left: 5%!important;
    margin-bottom: 2%!important;

}
.text{
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: black;
    margin-top: 15px;
    margin-bottom: 15px;
}


.card-img-top{
    width: 100%!important;
    height: 300px!important;
}


@media only screen and (max-width: 768px){
    .col{
        width: 100%;
    }
}
.overlay{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 0;
    padding: 10px 20px;
    color: silver;
    background-color: black;
    opacity: 0.8;
    overflow: hidden;
    transition: 0.3s;
   
}

.card:hover .overlay {
    height: 100%;
    
}



.note {
    transform: translate(0, 0);
    margin-top: 10px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    color:gold
}

.player-wrapper {
    position: relative;
    padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
    
  }
  
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
}


@media (max-width: 600px) {
   
    .servicesOffMobile{
        display: none;
    }

    


    .card{
        
        box-shadow: 10px 15px 20px rgb(45, 49, 51)!important;
        height: 300px !important;
        width: 100% !important;
        margin-top: 2%!important;
        margin-left: -2px!important;
        margin-bottom: 2%!important;
    
    }

    .note {
        transform: translate(0, 0);
        margin-top: -18px;
        font-family: Georgia, 'Times New Roman', Times, serif;
        color:gold
    }

    br{
        display:block;
       
    }
}

